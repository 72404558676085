import React, { useState } from "react"
import { Row, Col, Card, CardBody, Button, Input, Nav, NavItem, NavLink, TabPane, TabContent, Label, CardText } from "reactstrap"
import MetaTags from 'react-meta-tags'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { getCustomerDetailsUI, searchUsers, clearCustomerData, getArealist as onGetArealist } from "../../store/actions"
import 'react-loading-skeleton/dist/skeleton.css'
import Pages404 from "pages/Utility/pages-404";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import classnames from "classnames"
import Select from "react-select"
import { postWeb } from "helpers/api_helper"
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CustomerDetails = () => {
  const dispatch = useDispatch()
  const { customers, users, customerDetailsUI, areas } = useSelector(state => ({
    customerDetailsUI: state.Customers.customerDetailsUI,
    customers: state.Customers.customers,
    users : state.Users.users,
    areas: state.ecommerce.arealist
  }))

  const [customerData, setCustomerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [mobile, setMobile] = useState(null)
  const [activeTab, setActiveTab] = useState("1")
  const [error, setError] = useState({})
  const [selectedArea, setSelectedArea] = useState("");
  const [isExport, setIsExport] = useState(false);

  const areaGroup = useSelector(state => areas.map(area => {
    return { label: area?.name, value: area?._id }
  }))

  let PhCode = process.env.REACT_APP_PHONE_PREFIX;
  useEffect(() => {
    dispatch(searchUsers({webPermissions: true}))
    dispatch(onGetArealist())
     // Cleanup function to reset data when component unmounts
     return () => {
      setCustomerData(null);
      setMobile(null);
      setActiveTab("1");
      dispatch(clearCustomerData()); // Dispatch action to clear customer data from Redux store
    };
  }, [])

  useEffect(() => {
    if(users.length> 0){
      setUserData(users[0]);
    }
  }, [users])

  // save customer details in customerData
  useEffect(() => {
    const _customerDetails = customerDetailsUI;
    if (_customerDetails && _customerDetails.record && _customerDetails.statusCode == 200) {
        const customerRecord = _customerDetails.record[0];
        setCustomerData(customerRecord);
    } else if (_customerDetails && _customerDetails.statusCode == 400) {
        toastr.error("Customer Not Found");
        setCustomerData(null);
    }
    setIsLoading(false);
}, [customerDetailsUI]);


  useEffect(() => {
    const _customers = customers?.record;

    if (_customers?.length) {
      setCustomerData(_customers)
    }
    setIsLoading(false);
  }, [customerData])

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleSearch = (e) => {
    setIsLoading(true);
    e.preventDefault();
      if(mobile){
        setIsLoading(true);
        dispatch(getCustomerDetailsUI({mobile: `${PhCode}${mobile}`}))
      }
      else{
        setError({...error, mobile: "Please Enter a Valid Mobile Number."})
      }
      setIsLoading(false);
  }

  const handleChange = (e) => {
    setError({});
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/[^\d]/g, '');

    if (value.length <= 10) {
      setMobile(value);
    }
  };

  const onExport = async (areaId) => {
    setIsLoading(true);
    try {
      const res = await postWeb("customers/exportCustomersData", { areaId: areaId.value });
      if (res.statusCode == 200 && res.filePath) {
        window.open(API_BASE_URL + "/" + res.filePath);
        toastr.success("Customers exported successfully");
        setSelectedArea(null);
      }
      else if (data.statusCode == 400) {
        toastr.error("Error while Downloading customers");
      }
    } catch (error) {
      toastr.error("Failed to export customers");
    } finally {
      setIsLoading(false);
    }
  };
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 2147483649,
    }),
  };
  
  

  if(userData?.permissions?.allPermissions==true || userData?.permissions?.orders?.read==true){
  return (
    <React.Fragment>
      <div className="page-content" >
        <MetaTags>
          <title>Kleen | Customer Details</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Customers" breadcrumbItem="Customer Details" />
          <Col sm="12">
                <div className="d-flex align-items-center">
                  <label className="d-flex align-items-center">
                    <input
                      type="radio"
                      checked={!isExport}
                      onChange={() => setIsExport(false)}
                    />
                    <span className="ms-2">Customer Details</span>
                  </label>
                  <label className="ms-4 d-flex align-items-center">
                    <input
                      type="radio"
                      checked={isExport}
                      onChange={() => setIsExport(true)}
                    />
                    <span className="ms-2">Export Customer Details</span>
                  </label>
                </div>
              </Col>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody style={{zIndex: 1}}>
                  <Row className="mb-2">
                    <Col sm="12">
                      {!isExport && <div className="d-flex justify-content-between mb-2">
                          <div>
                            <Label className="mb-2">Customer Mobile:</Label>
                            <div className="d-flex align-items-center gap-2 mb-2">
                              <Input
                                type="text"
                                placeholder="Search Customer"
                                className="form-control fw-semiBold"
                                value={mobile}
                                onChange={handleChange}
                                invalid={error?.mobile?.length > 0}
                                onKeyDown={e => {
                                  if (e.key === 'Enter') {
                                    handleSearch(e);
                                  }
                                }}
                              />
                              <Button color="primary" onClick={handleSearch}>Search</Button>
                            </div>
                            {error?.mobile?.length > 0 && <span className="text-danger mt-2">{error.mobile}</span>}
                          </div>
                      </div>}
                    { //if we get customer data after search render the customer details in 2 NavTabs (basic info and analytics)
                      customerData && !isExport ? (
                        <Col sm="12">
                          <Nav tabs className="nav-tabs-custom pt-1">
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "1",
                                })}
                                onClick={() => {
                                  toggle("1")
                                }}
                              >
                                <span>Basic Info</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "2",
                                })}
                                onClick={() => {
                                  toggle("2")
                                }}
                              >
                                <span>Analytics</span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={activeTab}>
                          <TabPane tabId="1">
                            <Row>
                              <Col sm="12">
                                <h4 className="card-title mt-2 pt-1">Basic Information</h4>
                                <div className="table-responsive pt-1">
                                  <table className="table table-nowrap mb-0">
                                    <tbody>
                                      <tr>
                                        <th className=" pb-0" scope="row">Customer ID</th>
                                        <td className=" pb-0">{customerData?.customerID}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Bag No.</th>
                                        <td className=" pb-0">{customerData?.bagNo ? customerData.bagNo : "N/A"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Category</th>
                                        <td className=" pb-0 text-capitalize">{customerData?.category ? customerData?.category : "N/A"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Name</th>
                                        <td className=" pb-0">{customerData?.name}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Mobile</th>
                                        <td className=" pb-0">{customerData?.mobile}</td>
                                      </tr>
                                      
                                      <tr>
                                        <th className=" pb-0" scope="row">Email</th>
                                        <td className=" pb-0">{customerData?.email ? customerData?.email : "N/A" }</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Area</th> 
                                        <td className=" pb-0 text-capitalize">{customerData?.location?.area?.name ? customerData?.location?.area?.name : "N/A" }</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Resident Type</th>
                                        <td className=" pb-0 text-capitalize">{customerData?.location?.type ? customerData?.location?.type : "N/A"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Wallet</th>
                                        <td className=" pb-0">{customerData?.wallet ? `${customerData.wallet} SR` : "N/A"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Device</th>
                                        <td className=" pb-0 text-capitalize">{customerData?.device ? customerData.device : "N/A"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">New Customer</th>
                                        <td className=" pb-0 text-capitalize">{customerData?.newCustomer ? "True" : "False"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Laundry Name</th>
                                        <td className=" pb-0">{customerData?.laundry?.nameEn ? customerData.laundry?.nameEn : "N/A"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Loyalty Points</th>
                                        <td className=" pb-0">{customerData?.loyaltyPoints ? customerData.loyaltyPoints : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Gifts</th>
                                        <td className=" pb-0">
                                        {customerData?.gifts 
                                          ? Object.entries(customerData.gifts).map(([key, value]) => (
                                              <div key={key}>
                                                - {key}: {value}
                                              </div>
                                            )) 
                                          : "N/A"}
                                      </td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Refund Balance</th>
                                        <td className=" pb-0">
                                        {customerData?.refundBalance 
                                          ? Object.entries(customerData.refundBalance).map(([key, value]) => (
                                              <div key={key}>
                                                - {key}: {value} SR
                                              </div>
                                            )) 
                                          : "N/A"}
                                      </td>
                                      </tr>
                                      <tr>
                                          <th className=" pb-0" scope="row">Delivery Fee</th>
                                          <td className=" pb-0">
                                            {customerData?.deliveryFee ? (
                                              <>
                                                {customerData.deliveryFee.avgBasketSizePerMonth !== undefined ? (
                                                  <div>- avgBasketSizePerMonth: {customerData.deliveryFee.avgBasketSizePerMonth} SR</div>
                                                ) : null}
                                                {customerData.deliveryFee.isNew !== undefined ? (
                                                  <div>- isNew: {customerData.deliveryFee.isNew ? "True" : "False"}</div>
                                                ) : null}
                                                {customerData.deliveryFee.firstOrderDate !== undefined ? (
                                                  <div>- firstOrderDate: {new Date(customerData.deliveryFee.firstOrderDate).toLocaleDateString()}</div>
                                                ) : null}
                                              </>
                                            ) : "N/A"}
                                          </td>
                                        </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Registration Date</th>
                                        <td className=" pb-0">{customerData?.registrationDateTime ? customerData.registrationDateTime.split("T",1) : "N/A"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Total Orders</th>
                                        <td className=" pb-0">{customerData?.totalOrders ? customerData?.totalOrders : 0}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Row>
                              <Col sm="12">
                                <h4 className="card-title mt-2 pt-1">Customer Analytics</h4>
                                <div className="table-responsive">
                                  <table className="table table-nowrap mb-0">
                                    <tbody>
                                      <tr>
                                        <th className=" pb-0" scope="row">Total Online Orders</th>
                                        <td className=" pb-0">{customerData?.totalOrders ? customerData?.totalOrders : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Total Offline Orders</th>
                                        <td className=" pb-0">{customerData?.analytics?.offlineOrders ? customerData?.analytics?.offlineOrders : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Total Online Discount</th>
                                        <td className=" pb-0">{customerData?.analytics?.totalDiscount?.online ? `${customerData.analytics?.totalDiscount?.online} SR` : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Total Offline Discount</th>
                                        <td className=" pb-0">{customerData?.analytics?.totalDiscount?.offline ? `${customerData.analytics?.totalDiscount?.offline} SR` : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Total Offline Order Amount</th>
                                        <td className=" pb-0">{customerData?.analytics?.totalOfflineOrderAmount ? `${customerData?.analytics?.totalOfflineOrderAmount.toFixed(2)} SR` : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Total Online Order Amount</th>
                                        <td className=" pb-0">{customerData?.analytics?.totalOnlineOrderAmount ? `${customerData?.analytics?.totalOnlineOrderAmount.toFixed(2)} SR` : `0 SR`}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Average Online Basket Size</th>
                                        <td className=" pb-0">{customerData?.analytics?.avgBasketSize?.online ? `${customerData?.analytics?.avgBasketSize?.online} SR` : "0 SR"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Average Offline Basket Size</th>
                                        <td className=" pb-0">{customerData?.analytics?.avgBasketSize?.offline ? `${customerData?.analytics?.avgBasketSize?.offline} SR` : "0 SR"}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Average Online Orders Per Week</th>
                                        <td className=" pb-0">{customerData?.analytics?.avgOrdersPerWeek?.online ? customerData?.analytics?.avgOrdersPerWeek?.online : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Average Offline Orders Per Week</th>
                                        <td className=" pb-0">{customerData?.analytics?.avgOrdersPerWeek?.offline ? customerData?.analytics?.avgOrdersPerWeek?.offline : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">First Offline Order Date</th>
                                        <td className=" pb-0">{customerData?.analytics?.firstOfflineOrderDate ? customerData?.analytics?.firstOfflineOrderDate.split("T", 1) : "N/A" }</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Offline Orders</th>
                                        <td className=" pb-0">{customerData?.analytics?.offlineOrders ? customerData?.analytics?.offlineOrders : 0}</td>
                                      </tr>
                                      <tr>
                                        <th className=" pb-0" scope="row">Average Rating</th>
                                        <td className=" pb-0">{customerData?.analytics?.avgRating ? customerData?.analytics?.avgRating.toFixed(2) : "N/A"}</td>
                                      </tr>
                                    </tbody>
                                   </table>
                                </div> 
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                        </Col>
                      ) : 
                      (
                        <>
                          {isExport && (
                            <Col sm="6" className="mt-3">
                              <Row className="d-flex justify-content-end align-items-center mb-3">
                              <CardText >Export Customer Details</CardText>
                                <div>
                                <Label className=" me-2" for="areaSelect">Select Area</Label>
                                <Select
                                styles={customStyles}
                                  id="areaSelect"
                                  placeholder="Select Area"
                                  options={areaGroup}
                                  value={selectedArea}
                                  onChange={option => setSelectedArea(option)}
                                  className="me-2 mb-2 w-50"
                                />
                                <Button
                                  color="primary"
                                  onClick={() => { onExport(selectedArea) }}
                                  disabled={isLoading || !selectedArea}
                                >
                                  {isLoading ? 'Exporting...' : 'Export'}
                                </Button>
                                </div>
                              </Row>
                            </Col>
                          )}
                        </>
                      )
                    }
                    </Col>
                  </Row>
                  {
                    isLoading ? (
                      <div className="row">
                        <div className="col-xl-12">
                          {/* Customer details after search. */}
                        </div>
                      </div>
                    ) : null
                    // (
                      // <PaginationProvider
                      //   pagination={paginationFactory(pageOptions)}
                      //   keyField='id'
                      //   columns={columns}
                      //   data={customerData}
                      // >
                      //   {({ paginationProps, paginationTableProps }) => (
                      //     <ToolkitProvider
                      //       keyField='id'
                      //       columns={columns}
                      //       data={customerData}
                      //       search
                      //     >
                      //       {toolkitProps => (
                      //         <React.Fragment>
                      //           <Row>
                      //             <Col xl="12">
                      //               <div className="table-responsive">
                      //                 {/* <BootstrapTable
                      //                   keyField={"id"}
                      //                   responsive
                      //                   bordered={false}
                      //                   striped={false}
                      //                   defaultSorted={defaultSorted}
                      //                   rowEvents={rowEvents}
                      //                   // selectRow={selectRow}
                      //                   classes={
                      //                     "table align-middle table-nowrap"
                      //                   }
                      //                   headerWrapperClasses={"table-light"}
                      //                   // headerWrapperClasses={"thead-light"}
                      //                   {...toolkitProps.baseProps}
                      //                   {...paginationTableProps}
                      //                 /> */}

                      //               </div>
                      //             </Col>
                      //           </Row>
                      //         </React.Fragment>
                      //       )
                      //       }
                      //     </ToolkitProvider>
                      //   )
                      //   }</PaginationProvider> 
                    // )
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
  }
  else{
   return <Pages404/> 
  }
}

export default CustomerDetails
